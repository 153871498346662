/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import ReactDOM from 'react';
import './src/styles/App.scss';

const loadableReady = require('@loadable/component').loadableReady;

export const replaceHydrateFunction = () => {
	return (element, container, callback) => {
		loadableReady(() => {
			ReactDOM.render(element, container, callback);
		});
	};
};

// export const onRouteUpdate = () => {
//   navigator.serviceWorker.register('/sw.js').then((reg) => {
//     reg.update();
//   });
// };

// export const onServiceWorkerUpdateReady = () => {
//   window.location.reload(true)
// };
